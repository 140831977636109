import {
  AspectRatio,
  Box,
  Button,
  chakra,
  Checkbox,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Heading,
  Input,
  VStack,
  Link as ChakraLink,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import axios from 'axios'
import { useFormik } from 'formik'
import Image from 'next/image'
import { Fragment, useState } from 'react'
import { Element } from 'react-scroll'

import { Link } from '@/lib/Link'
import env from '@/config/env'

import { Section } from './Registration.styles'
import { registrationFormSchema } from '../../schema'

const ChakraBr = chakra('br')

export const Registration = (
  props: ReferralSectionProps,
): React.ReactElement => {
  const { id } = props

  return (
    <Section as="section" id={id}>
      <Element name={id} />

      <Box sx={{ pos: 'relative' }}>
        <AspectRatio
          ratio={1285 / 880}
          sx={{
            pos: 'absolute',
            minW: '1185px',
            left: { base: '-60%', md: '50%' },
            transform: {
              base: 'translate(0%, 10%)',
              md: 'translate(-50%, 20%)',
            },
          }}
        >
          <Image
            src="/images/Landing/referral/form-bg.svg"
            layout="fill"
            objectFit="contain"
            alt=""
          />
        </AspectRatio>
      </Box>

      <Container className="container">
        <Heading as="h2">ลงทะเบียนเลย!</Heading>

        <Box className="form-wrapper">
          <Text sx={{ fontSize: '20px', textAlign: 'center', mb: '24px' }}>
            แบบฟอร์มลงทะเบียน
          </Text>

          <RegisterForm />
        </Box>

        <Stack
          className="partner-banner"
          direction={{ base: 'column', lg: 'row' }}
          spacing={6}
        >
          <AspectRatio ratio={1 / 1} className="partner-banner-image">
            <Image
              src="/images/Landing/referral/register-left.svg"
              layout="fill"
              objectFit="contain"
              alt=""
            />
          </AspectRatio>

          <Text>ไม่ว่าใครก็สามารถเป็น Partner กับเราได้!</Text>

          <AspectRatio ratio={1 / 1} className="partner-banner-image">
            <Image
              src="/images/Landing/referral/register-right.svg"
              layout="fill"
              objectFit="contain"
              alt=""
            />
          </AspectRatio>
        </Stack>
      </Container>
    </Section>
  )
}

const RegisterForm = () => {
  const [isLoading, setLoading] = useState(false)
  const toast = useToast()

  const { onOpen, isOpen, onClose } = useDisclosure()

  const { errors, values, handleChange, handleSubmit } =
    useFormik<RegistrationForm>({
      initialValues: {
        name: '',
        email: '',
        tel: '',
        company: '',
        message: '',
        accept: false,
        newsletter: false,
      },
      onSubmit: async (data, { resetForm }) => {
        setLoading(true)
        try {
            // Make a POST request to the '/api/registration' endpoint with the form data
            await axios.post('/api/registration', data)
          await axios.post('/api/registration', data)
          toast({
            title: 'Email Sent!',
            description: 'Our customer service will contact you soon!',
            status: 'success',
            position: 'top',
          })
          await axios.post(env.SLACK_API_URL as string, {
            webhook_url: env.SLACK_WEBHOOK_URL,
            text:
              `[PDPA Pro] Referal Form! \n` +
              `Name: ${data.name}\n` +
              `Email: ${data.email}\n` +
              `Tel: ${data.tel}\n` +
              `Company: ${data.company}\n` +
              `Message: ${data.message}\n` +
              `Term and Policy: ${data.accept}\n` +
              `Newsletter: ${data.newsletter}`,
          })
          onOpen()
          resetForm()
        } catch (error) {
          toast({
            title: 'An error occurred',
            description: 'Please try again later',
            status: 'error',
            position: 'top',
          })
        }

        setLoading(false)
      },
      validationSchema: registrationFormSchema,
      validateOnChange: false,
    })

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <Grid className="form-container">
          <FormControl isRequired isInvalid={Boolean(errors.name)}>
            <FormLabel>ชื่อ-นามสกุล</FormLabel>
            <Input
              size="lg"
              placeholder="ชื่อของคุณ"
              name="name"
              value={values.name}
              onChange={handleChange}
            />
            {errors.name && <FormErrorMessage>{errors.name}</FormErrorMessage>}
          </FormControl>

          <FormControl isRequired isInvalid={Boolean(errors.email)}>
            <FormLabel>อีเมลสำหรับติดต่อ</FormLabel>
            <Input
              size="lg"
              type="email"
              placeholder="อีเมล"
              name="email"
              value={values.email}
              onChange={handleChange}
            />
            {errors.email && (
              <FormErrorMessage>{errors.email}</FormErrorMessage>
            )}
          </FormControl>

          <FormControl isRequired isInvalid={Boolean(errors.tel)}>
            <FormLabel>เบอร์โทรติดต่อ</FormLabel>
            <Input
              size="lg"
              placeholder="012 345 6789"
              name="tel"
              inputMode="tel"
              value={values.tel}
              onChange={handleChange}
            />
            {errors.tel && <FormErrorMessage>{errors.tel}</FormErrorMessage>}
          </FormControl>

          <FormControl>
            <FormLabel>
              ชื่อบริษัท/แบรนด์/เพจ&nbsp;
              <Text as="span" className="optional">
                (ถ้ามี)
              </Text>
            </FormLabel>
            <Input
              size="lg"
              placeholder="บริษัท ตัวอย่าง จำกัด"
              name="company"
              value={values.company}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl sx={{ gridColumn: '1/-1' }}>
            <FormLabel>
              ข้อสงสัยเพิ่มเติม&nbsp;
              <Text as="span" className="optional">
                (ถ้ามี)
              </Text>
            </FormLabel>
            <Textarea
              rows={4}
              placeholder="ระบุข้อสงสัย"
              name="message"
              value={values.message}
              onChange={handleChange}
              size="lg"
              focusBorderColor="primary.400"
            />
          </FormControl>

          <FormControl sx={{ gridColumn: '1/-1' }}>
            <Stack spacing={2}>
              <Checkbox
                colorScheme="primary"
                name="accept"
                size="lg"
                alignItems="flex-start"
                sx={{ span: { fontSize: '16px', fontWeight: 'light' } }}
                isChecked={values.accept}
                onChange={handleChange}
                isRequired
              >
                ฉันยอมรับเงื่อนไข&nbsp;
                <Link route="referralTerms">
                  <ChakraLink>ข้อตกลงในการใช้บริการ</ChakraLink>
                </Link>
                &nbsp;และ&nbsp;
                <Link route="privacy">
                  <ChakraLink>นโยบายความเป็นส่วนตัว</ChakraLink>
                </Link>
              </Checkbox>
              <VStack sx={{ alignItems: 'stretch', fontWeight: 'light' }}>
                <Checkbox
                  colorScheme="primary"
                  name="newsletter"
                  size="lg"
                  alignItems="flex-start"
                  isChecked={values.newsletter}
                  onChange={handleChange}
                  sx={{
                    span: { fontSize: '16px', fontWeight: 'light' },
                  }}
                >
                  ฉันต้องการรับโปรโมชันและข่าวสารทางการตลาดเกี่ยวกับ PDPA Pro
                  และบริการอื่น ๆ จากเรา บริษัทในเครือ
                  บริษัทย่อยและพันธมิตรทางธุรกิจ &#8203;
                  <span style={{ whiteSpace: 'nowrap' }}>
                    (คุณสามารถยกเลิกได้ทุกเมื่อ)
                  </span>
                </Checkbox>
                <Text
                  sx={{
                    fontSize: 'sm',
                    color: 'customGray.700',
                    pl: 7,
                    textAlign: 'left',
                  }}
                >
                  PDPA Pro
                  อาจติดต่อคุณในบางโอกาสเพื่อให้ข้อมูลที่เป็นประโยชน์และเกี่ยวข้องกับสินค้าและบริการที่คุณเลือกใช้
                </Text>
              </VStack>
            </Stack>
          </FormControl>

          <Button
            type="submit"
            sx={{ gridColumn: '1/-1', h: '72px', fontSize: '20px' }}
            size="xl"
            colorScheme="primary"
            isLoading={isLoading}
          >
            เข้าร่วมกับเรา
          </Button>
        </Grid>
      </form>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
          sx={{
            borderRadius: '20px',
            textAlign: 'center',
            p: { base: '24px', md: '40px' },
            w: {
              base: '90%',
              md: 'full',
            },
            minW: {
              base: '320px',
              md: '648px',
              'lg+': '720px',
            },
          }}
        >
          <Stack spacing={6} alignItems="center">
            <AspectRatio ratio={105 / 96} sx={{ w: '105px', h: '96px' }}>
              <Image
                src="/images/Landing/referral/submit-success.svg"
                layout="fill"
                objectFit="contain"
                alt=""
              />
            </AspectRatio>

            <Stack spacing="1">
              <ModalHeader
                sx={{ fontSize: '32px', fontWeight: 'normal', p: 0 }}
              >
                ลงทะเบียนสำเร็จ
              </ModalHeader>

              <Text
                sx={{
                  fontWeight: 300,
                  fontSize: {
                    base: '20px',
                    md: '24px',
                  },
                }}
              >
                ขอบคุณสำหรับความสนใจ
                <br />
                ทางทีมงานของเรา
                <ChakraBr
                  sx={{
                    d: {
                      base: 'block',
                      md: 'none',
                    },
                  }}
                />
                จะติดต่อกลับหาคุณอีกครั้ง
              </Text>
            </Stack>

            <Button
              colorScheme="primary"
              sx={{ w: 'full', h: '72px', fontSize: '20px' }}
              size="xl"
              onClick={onClose}
            >
              ตกลง
            </Button>
          </Stack>
        </ModalContent>
      </Modal>
    </Fragment>
  )
}
